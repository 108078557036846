import $ from 'jquery';
import ApexCharts from 'apexcharts'

export default class Chart {
    constructor() {
      this.$chart = document.querySelector('#chart')

    }

    init() {
      $.ajax({
        type: "GET",
        url: "assets/chart.csv",
        dataType: "text",
        success: (data)=> {
        const chart = new ApexCharts(this.$chart, {
          chart: {
            type: 'line',
            height: 410,
            parentHeightOffset: 0,
            locales: [
              {
                'name': 'de',
                'options': {
                  'months': [
                    'Januar',
                    'Februar',
                    'MÃ¤rz',
                    'April',
                    'Mai',
                    'Juni',
                    'Juli',
                    'August',
                    'September',
                    'Oktober',
                    'November',
                    'Dezember'
                  ],
                  'shortMonths': [
                    'Jan',
                    'Feb',
                    'MÃ¤r',
                    'Apr',
                    'Mai',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Okt',
                    'Nov',
                    'Dez'
                  ],
                  'days': [
                    'Sonntag',
                    'Montag',
                    'Dienstag',
                    'Mittwoch',
                    'Donnerstag',
                    'Freitag',
                    'Samstag'
                  ],
                  'shortDays': ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                  'toolbar': {
                    'exportToSVG': 'SVG speichern',
                    'exportToPNG': 'PNG speichern',
                    'exportToCSV': 'CSV speichern',
                    'menu': 'MenÃ¼',
                    'selection': 'Auswahl',
                    'selectionZoom': 'Auswahl vergrÃ¶Ãern',
                    'zoomIn': 'VergrÃ¶Ãern',
                    'zoomOut': 'Verkleinern',
                    'pan': 'Verschieben',
                    'reset': 'Zoom zurÃ¼cksetzen'
                  }
                }
              }
            ],
            defaultLocale: 'de',
          },
          series: [
            {
              name: 'Kurs',
              data: this.processData(data)[2]
            }
          ],
          xaxis: {
            type: 'datetime',
            labels: {
              formatter: function (value) {
                const date = new Date(null);
                date.setSeconds(value);
                return date.toLocaleDateString();
              }
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: true
              }
            },
            yaxis: {
              lines: {
                show: true
              }
            }
          },
          tooltip: {
            x: {
              formatter: function (value) {
                const date = new Date(null);
                date.setSeconds(value);
                return date.toLocaleDateString();
              }
            },
            y: {
              formatter: function (value) {
                const valueFormatter = new Intl.NumberFormat('de')
                return valueFormatter.format(value)
              },
            },
          }
        })

        chart.render();
        }
     });
    }
  
  processData(allText) {
    const allTextLines = allText.split(/\r\n|\n/);
    const times = [];
    const priceValues  = []
    const pairs = []

    allTextLines.slice(0,-1).forEach((e)=>{
      const entries = e.split(',');
      const date = new Date(null);
      date.setSeconds(parseInt(entries[0]));
      const result = date.toLocaleDateString();

      pairs.push([parseInt(entries[0]),parseInt(entries[1])])
      times.push(result)
      priceValues.push(entries[1])
    })
    return [times,priceValues,pairs]
  }

}
