import $ from 'jquery';

export default class Slider {
    constructor() {
    }

    init() {
        this._addListeners();
    }

    _addListeners() {
        $('.slider').slick({
            prevArrow: "<div data-direction='left' class='arrow-btn'><img class='lazyload slick-prev' type='button' src='/assets/img/icons/arrow_light_grey.svg' alt='arrow left'></div>",
            nextArrow: "<div data-direction='right' class='arrow-btn arrow-right'><img class='lazyload slick-next' type='button' src='/assets/img/icons/arrow_light_grey.svg' alt='arrow right'></div>",
        });
        $('.slider-2').slick({
            prevArrow: $('.arrow-left'),
            nextArrow: $('.arrow-right'),
            adaptiveHeight: true
        });
    }
}
