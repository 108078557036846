import $ from 'jquery';

export default class ContactForm {
    constructor() {
    }

    init() {
        this._addListeners();
    }

    _addListeners() {
        $('#contact-btn').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            $('#contact-form-modal')
                .addClass('active')
                .find('#modal-close, #modal-bg')
                .on('click', () => {
                    $('#contact-form-modal').removeClass('active');
                });
        });
    }
}
