import $ from 'jquery';

export default class Header {
    constructor() {
    }

    init() {
        this._addListeners();
    }

    _addListeners() {
        $(".header .menu-btn").on('click', () => {
            $(".header .menu-btn").toggleClass("expanded");
            $(".header .menu").toggleClass("expanded");
            $('a.newsletter').on('click', () => {
                $(".header .menu").removeClass("expanded");
                $(".header .menu-btn").removeClass("expanded");
            })
        });
    }
}
