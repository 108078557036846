import $ from 'jquery';

export default class News {
  constructor() {
    this.$nbrNewsShown = 3
  }

  init() {
    this._addListeners();
    $('.no-btc-news').addClass('hide')
    this._showNextTwo()

    if($('.no-btc-news').length <= this.$nbrNewsShown){
      $('.show-more').hide()
    }
    
  }

  _addListeners() {
    $('.show-more').on('click',() => this._showNextTwo())
  }

  _showNextTwo() {
    let count = 0
    $('.no-btc-news').each((i,el) => {
      if($(el).hasClass('hide') && count <  this.$nbrNewsShown){
        $(el).removeClass('hide')
        count++
      }
    })

    this._hideMoreBtn()
  }

  _hideMoreBtn(){
    if(!$('.no-btc-news').hasClass('hide')){
      $('.show-more').hide()
    }
  }
}
