import $ from 'jquery';
import  'slick-carousel';

import Header from './sections/header';
import Slider from './slider/slider';
import ContactForm from "./sections/contact-form-modal";
import News from './sections/news';
import Chart from './sections/chart'

import Choices from 'choices.js';
import LazyLoad from 'vanilla-lazyload';

$(document).ready(() => {
    (new Slider()).init();
    (new Header()).init();
    (new ContactForm()).init();
    (new News()).init();
    (new Chart()).init();

    new LazyLoad({
        elements_selector: ".lazyload",
    });

    if ($('select').length > 0) {
        new Choices('select', {
            itemSelectText: '',
            searchEnabled: false,
            shouldSort: false,
            shouldSortItems: false
        });
    }
});

